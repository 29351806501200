import React, {
  useEffect,
  useState,
  useContext,
  useReducer,
  useCallback,
  useRef
} from 'react'
import styled, { keyframes, css } from 'styled-components'
import {
  map,
  path,
  uniq,
  o,
  unnest,
  compose,
  filter,
  contains,
  last,
  anyPass,
  split,
  applySpec,
  sortBy,
  groupBy,
  head,
  toPairs,
  evolve,
  length,
  append,
  init,
  always,
  unless,
  join,
  toLower,
  reduce,
  find,
  isEmpty,
  flip, includes, without,
  lt,
  flatten,
  pick,
  prop,
  values,
  trim,
  memoizeWith
} from 'ramda'
import Select from '@material-ui/core/Select'
import Checkbox from 'components/Checkbox'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import Popover from '@material-ui/core/Popover'
import Paper from '@material-ui/core/Paper'
import Search from '@material-ui/icons/Search'
import MenuItem from '@material-ui/core/MenuItem'
import NavContext from 'components/NavProvider'
import Image from 'components/Image'
import { mediaQuery } from 'utils/style'
import SwipeableViews from 'react-swipeable-views'
import Description from 'components/ImageTextOverlay'
import Container from 'components/Container'

const gtmPush = memoizeWith(prop('id'), download => () => {
  dataLayer.push({ event: 'download', downloadSlug: download.slug })
})

const Types = styled.div`
  scroll-margin-top: 200px;
  width: 100%;
`

const Background = styled.div`
  && {
    padding-top: 5px;
  }
  background-color: white;
  z-index: 501;
`

const Checkboxes = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding:32px;
  font-size: 0.8rem;
  > * {
    flex-basis: calc(50% - 5px);
    -ms-flex: 1 0 auto;
  }
`

const Content = styled.div`
  display: flex;
  flex:1;
  filter: drop-shadow(2px 2px 5px rgba(0,0,0,0.5));
`

const SelectContainer = styled.div`
  flex: 1;
  padding: 32px;
  background-color: white; 
`

const SearchContainer = styled.div`
  display: flex;
  width: 120px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
  font-size: 0.8rem;
  color: black;
  background-color: ${({ theme }) => theme.colors.base.lightGray};
`

const StyledInputLabel = styled(InputLabel)`
  && {
    font-weight: bold;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.base.blue};
  }
`

const StyledSelect = styled(Select)`
  flex: 1;
  font-weight: bold;
  background-color: white;
`

const blink = keyframes`
  0% { background-color: white; }
  50% { background-color: #e6e0d8; }
  100% {background-color: white; }
`

const StyledInput = styled(Input)`
  ${({ animate }) => (animate ? css`animation: ${blink} 2s cubic-bezier(0,1,0,1) infinite;` : '')}
`

const Expand = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.base.orange};
  border-right: 2px solid ${({ theme }) => theme.colors.base.orange};
  height: 1em;
  width: 1em;
  transform: translate(-20%) rotate(45deg) translate(-40%);
`

const Type = styled.div`
  &&& {
    ${mediaQuery(true)}
  }
  > h3 {
    text-transform: uppercase;
  }
  :nth-child(2n) {
    background: ${({ theme }) => theme.colors.base.lightestGray};
  }
`
const Group = styled.div`
  padding: 16px 0;
  margin: 0 32px;
  position: relative;
  ${mediaQuery()({
    desktop: `.react-swipeable-view-container {
    width: 250px !important;
  }`
  })}
`

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Autocomplete = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  > div:first-child {
    + * {
      display: none;
    }
    :focus-within {
      + * {
        display: initial;
      }
    }
  }
`

const Suggestions = styled(Paper)`
  z-index: 501;
  position: absolute;
  top: 48px;
`

const SubMenuItem = styled(MenuItem)`
  padding-left: 32px;
`

const Name = styled.p`
  width: 258px;
  ${mediaQuery()({ desktop: 'width: 218px;' })}
`

const Download = styled.div`
  position: relative;
  width: 258px;
  ${mediaQuery()({ desktop: 'width: 218px;' })}
  :hover {
    ${Description} {
      opacity: 0.7;
    }
  }
`

const Previous = styled.div`
  z-index: 1;
  position: absolute;
  left: -42px;
  padding: 10px;
  top: 50%;
  ${({ index }) => (index ? '' : 'display: none;')}
  transform: translateY(-50%);
`
const ArrowLeft = styled.div`
  width: 2rem;
  height: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.base.orange};
  border-left: 1px solid ${({ theme }) => theme.colors.base.orange};
  transform: rotate(45deg);
`

const Next = styled.div`
  z-index: 1;
  position: absolute;
  right: -42px;
  padding: 10px;
  top: 50%;
  ${({ index, length }) => mediaQuery()({ max: index >= length - 5 ? 'display: none;' : 'display: initial;', desktop: index >= length - 4 ? 'display: none;' : 'display: initial;', tablet: index == length ? 'display: none;' : 'display: initial;' })}
  width: 2rem;
  height: 2rem;
  transform: translateY(-50%);
`

const ArrowRight = styled.div`
  width: 2rem;
  height: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.base.orange};
  border-right: 1px solid ${({ theme }) => theme.colors.base.orange};
  transform: rotate(-45deg);
`

function DownloadGroup ({ group, downloads }) {
  const [index, setIndex] = useState(0)

  return (
    <Group>
      <Previous index={index} onClick={() => setIndex(prev => Math.max(0, prev - 1))}><ArrowLeft /></Previous>
      <Next index={index} length={downloads.length} onClick={() => setIndex(prev => Math.min(prev + 1, downloads.length - 1))}><ArrowRight /></Next>
      <h5>{group}</h5>
      <SwipeableViews
        index={index}
        onChangeIndex={setIndex}
        containerStyle={{ width: '290px' }}
        overscanSlideBefore={25}
        overscanSlideAfter={25}
        slideStyle={{ padding: '16px' }}
      >
        {map(
          download => (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                download.component.slug
                || path(['component', 'file', 'localFile', 'publicURL'], download)
              }
              onClick={gtmPush(download)}
            >
              <Download>
                <Image
                  style={{ height: '360px' }}
                  src={download.component.image}
                  alt={download.component.imageAlt}
                  title={download.component.imageTitle}
                  description={download.component.imageDescription}
                  imageStyle="object-fit: cover !important;"
                />
                <Description>
                  <b>{download.component.name || ''}</b><br /><br />
                  {download.component.description}
                </Description>
              </Download>
              <Name>{download.component.name}</Name>
            </a>
          ),
          downloads
        )}
      </SwipeableViews>
    </Group>
  )
}

export default function StoryblokDownloads ({ data, ...props }) {
  const typesRef = useRef()
  const typeSelectRef = useRef()
  const [typeSelect, setTypeSelect] = useState(false)
  const timeout = useRef()
  const { lang, history } = useContext(NavContext) || {}
  const [{
    downloads, query, selectedGroups, suggest
  }, setState] = useReducer(
    (state, action) => {
      const newState = { ...state, ...action }
      const allTags = map(
        toLower,
        uniq(
          flatten(
            map(
              compose(
                values,
                pick(['group', 'tags']),
                prop('component')
              ),
              newState.downloads
            )
          )
        )
      )
      const keywords = compose(
        reduce(
          (acc, value) =>
            (last(acc) && find(contains(join(' ', [last(acc), value])), allTags)
              ? append(join(' ', [last(acc), value]), init(acc))
              : find(contains(value), allTags)
                ? append(value, acc)
                : acc),
          []
        ),
        filter(o(lt(2), length)),
        split(' '),
        toLower
      )(newState.query)

      clearTimeout(timeout.current)
      if (newState.query) timeout.current = setTimeout(() => typesRef.current.scrollIntoViewIfNeeded(), 500)

      return {
        ...newState,
        suggest: applySpec({
          keywords: compose(
            filter(o(anyPass(map(contains, keywords)), toLower)),
            uniq,
            unnest,
            map(path(['component', 'tags']))
          ),
          groups: compose(
            filter(o(anyPass(map(contains, keywords)), toLower)),
            uniq,
            unnest,
            map(path(['component', 'group']))
          ),
          downloads: compose(
            filter(path(['component', 'tags'])),
            map(
              evolve({
                component: {
                  tags: compose(
                    length,
                    unless(
                      always(isEmpty(keywords)),
                      filter(anyPass(map(contains, keywords)))
                    ),
                    map(toLower)
                  )
                }
              })
            ),
            unless(
              always(isEmpty(newState.selectedGroups)),
              filter(
                o(
                  anyPass(map(contains, newState.selectedGroups)),
                  path(['component', 'group'])
                )
              )
            )
          )
        })(newState.downloads)
      }
    },
    {
      downloads: [],
      selectedGroups: [],
      query: history?.slice(-1)?.[0]?.context?.downloadQuery || '',
      suggest: {}
    }
  )
  const [selectedType, setType] = useState([])

  const handleTypeChange = useCallback((e) => {
    const { name, checked } = e.target
    setType((state) => {
      if (!name) {
        if (!state.length) return uniq(map(path(['component', 'type']), downloads))
        return []
      }
      return checked ? append(name, state) : without([name], state)
    })
  }, [setType])

  const getDownloads = async () => {
    try {
      const json = await import(`../../../.temp/${lang}/downloads.json`)
      setState({
        downloads: map(
          evolve({
            component: {
              group: o(map(trim), split(',')),
              tags: compose(filter(o(lt(2), length)), map(trim), split(','))
            }
          }),
          filter(path(['component', 'file', 'url']), json.default)
        )
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getDownloads()
  }, [lang])

  const updateQuery = useCallback((e) => {
    setState({ query: e.target.value })
  }, [])

  useEffect(() => {}, [query, downloads, selectedGroups])

  return (
    <>
    <Container bg="black" extraWide max="display: flex; position: sticky; top: 0;" {...props}>
    <Content justify="space-evenly">

      <SelectContainer>
        <FormControl fullWidth>
          <StyledInputLabel htmlFor="type" shrink>
            {data.typeTranslation}
          </StyledInputLabel>
          <StyledInput
            animate={!selectedType.length && !query}
            ref={typeSelectRef}
            fullWidth
            placeholder={data.typePlaceholder}
            value={selectedType.join(', ')}
            onClick={() => setTypeSelect(true)}
            endAdornment={<InputAdornment position="end"><Expand /></InputAdornment>}
          />
          <Popover open={typeSelect} anchorEl={typeSelectRef.current} onClose={() => setTypeSelect(false)}>
            <Checkboxes>
              <Checkbox onChange={handleTypeChange} checkedColor="blue" checked={!selectedType.length} label={data.anyTranslation} />
              {map(
                type => (
                  <Checkbox onChange={handleTypeChange} name={type} checkedColor="blue" checked={selectedType.indexOf(type) > -1} label={type} key={type} />
                ),
                uniq(map(path(['component', 'type']), downloads))
              )}
            </Checkboxes>
          </Popover>
        </FormControl>
      </SelectContainer>


      <SelectContainer>
        <FormControl fullWidth>
          <StyledInputLabel htmlFor="search" shrink>{data.searchLabel}</StyledInputLabel>
          <Input
            fullWidth
            type="search"
            placeholder={data.searchPlaceholder}
            onChange={updateQuery}
            value={query}
          />
        </FormControl>
      </SelectContainer>
      <SearchContainer><Search />{data.searchLabel}</SearchContainer>
    </Content>
  </Container>
  <Types ref={typesRef}>
      {compose(
        map(([type, children]) => (
          <Type extraWide root>
            <h3>{type}</h3>
            {compose(map(([group, dls]) => (
              <DownloadGroup group={group} downloads={dls} />
            )),
            toPairs,
            groupBy(o(join(' > '), path(['component', 'group']))))(children)}
          </Type>
        )),
        unless(always(isEmpty(selectedType)), filter(o(flip(includes)(selectedType), head))),
        toPairs,
        groupBy(path(['component', 'type'])),
        sortBy(path(['component', 'sortKey']))
      )(suggest.downloads || downloads)}
      </Types>
      

    </>
  )
}
